import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollWrapper/ScrollToTop";
import styles from "../src/styles/app.module.css";
import React, { Suspense, lazy } from "react";


// import LandingPage from "./pages/LandingPage/LandingPage";
// import AboutPage from "./pages/About/about";
// import AMLPolicyPage from "./pages/AMLPolicy/amlpolicy";
// import ExchangeRatePage from "./pages/ExchangeRate/exchange";
// import FAQPage from "./pages/FAQ/faq";
// import GiftcardPage from "./pages/Giftcards/giftcard";

// import MobileAppPage from "./pages/MobileApp/mobile";
// import PrivacyPage from "./pages/PrivacyPage/privacy";
// import ReferEarnPage from "./pages/Refer&Earn/refer";
// import TermsPage from "./pages/TermsPage/terms";

//LAZY LOADING
const LandingPage = lazy(() => import("./pages/LandingPage/LandingPage"));
const AMLPolicyPage = lazy(() => import("./pages/AMLPolicy/amlpolicy"));
const AboutPage = lazy(() => import("./pages/About/about"));
const ExchangeRatePage = lazy(() => import("./pages/ExchangeRate/exchange"));
const FAQPage = lazy(() => import("./pages/FAQ/faq"));
const GiftcardPage = lazy(() => import("./pages/Giftcards/giftcard"));

const MobileAppPage = lazy(() => import("./pages/MobileApp/mobile"));
const PrivacyPage = lazy(() => import("./pages/PrivacyPage/privacy"));
const ReferEarnPage = lazy(() => import("./pages/Refer&Earn/refer"));
const TermsPage = lazy(() => import("./pages/TermsPage/terms"));
const SnapChat = lazy(() => import("./pages/Snapchat/snapchat"));
const NotFound = lazy(() => import("./pages/NotFound/notFound"));

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className={styles.container}>
              <div className={styles.loader}></div>
            </div>
          }
        >
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/referandearn" element={<ReferEarnPage />} />
            <Route path="/faqs" element={<FAQPage />} />
            <Route path="/mobile-app" element={<MobileAppPage />} />
            <Route path="/exchange-rate" element={<ExchangeRatePage />} />
            <Route path="/giftcards" element={<GiftcardPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/amlpolicy" element={<AMLPolicyPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/snapchat" element={<SnapChat />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

export default App;
